import * as React from "react";

interface SVGRProps {
  title: string;
  titleId: string;
}

const SvgWarning = (
  { title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps,
  svgRef?: React.Ref<SVGSVGElement>,
) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={svgRef}
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M12 16.783h.01M12 9.127v3.828m-1.637-8.748L2.257 17.74a1.914 1.914 0 001.636 2.87h16.214a1.914 1.914 0 001.637-2.87L13.637 4.207a1.915 1.915 0 00-3.274 0z"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const ForwardRef = React.forwardRef(SvgWarning);
export default ForwardRef;
