import * as React from "react";

interface SVGRProps {
  title: string;
  titleId: string;
}

const SvgInfo = (
  { title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps,
  svgRef?: React.Ref<SVGSVGElement>,
) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={svgRef}
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M10 10h2v7m0 0H9m3 0h3m7-5c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10z"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13 7a1 1 0 11-2 0 1 1 0 012 0z"
      fill="currentColor"
      stroke="currentColor"
    />
  </svg>
);

const ForwardRef = React.forwardRef(SvgInfo);
export default ForwardRef;
